<template>
  <v-container fluid class="px-0">
    <v-row>
      <v-col>
        <template>
          <v-card elevation="0">
            <v-data-table
              :headers="TransitModelHeader"
              :items="allchalanArray"
              :search="searchchalan"
              :loading="loading"
              :server-items-length="totalItems1"
              hide-default-footer
              loading-text="Please wait..."
              item-key="id"
              v-model="selected"
              :single-select="singleSelect"
              show-select
              class="elevation-0"
              style="background: #f5f5f5"
              return-object
            >
              <template v-slot:top>
                <v-toolbar
                  elevation="2"
                  style="background: #f5f5f5; font-size: 26px"
                >
                  <v-toolbar-title
                    style="margin-left: -14px"
                    class="mt-2 mb-2 ml-2"
                  >
                    <v-select
                      v-model="trnasferob"
                      :items="AllWarehouseList"
                      item-text="name"
                      @change="TransferSelectInfo(trnasferob)"
                      return-object
                    ></v-select>
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <div class="pa-5">
                    <v-text-field
                      v-model="searchchalan"
                      append-icon="mdi-magnify"
                      label="Search"
                      filled
                      rounded
                      dense
                      hide-details
                    ></v-text-field>
                  </div>
                  <div class="pa-5">
                    <v-btn
                      color="primary"
                      @click="savetransit(item)"
                      :disabled="!selected.length"
                    >
                      Create Chalan
                    </v-btn>
                  </div>
                  <v-divider class="mx-4" inset vertical></v-divider>
                </v-toolbar>
              </template>
              <template
                v-slot:item.actionsqty="{ item }"
                style="max-width: 30px"
              >
                <v-text-field
                  v-model="item.requested_qty"
                  outlined
                  dense
                  class="mt-5"
                  style="max-width: 100px"
                ></v-text-field>
              </template>

              <template v-slot:item.admin_status="{ item }">
                <v-chip :color="getColor(item.admin_status)" dark>
                  {{ item.admin_status }}
                </v-chip>
              </template>
            </v-data-table>
            <div class="d-flex justify-content-center mt-5">
              <!-- <v-col cols="3" md="3"> -->
              <div class="col-4">
                  Total {{ totalItems1 }} records
              </div>
              <!-- </v-col> -->
              <!-- <v-col cols="3" md="3"> -->
              <div class="col-4">
                  <v-select dense outlined hide-details v-model="perPage1" label="Items per page" @change="handlePageSizeChange" :items="pageSizes1" >
                  </v-select>                                                
              </div>
              <!-- </v-col> -->
              <!-- <v-col cols="6" md="3" > -->
              <div class="col-4">
                  <v-pagination v-model="currentPage1" :length="totalPages1" total-visible="7" @input="handlePageChange">
                  </v-pagination>
              </div>
              <!-- </v-col> -->
            </div>

            <v-dialog v-model="Transfersavedialog" max-width="900px">
              <v-card>
                <v-data-table
                  :headers="TransitModelHeader"
                  :items="selected"
                  item-key="id"
                  v-model="selected"
                  :single-select="singleSelect"
                  show-select
                  class="elevation-1"
                  style="background: #f5f5f5"
                  return-object
                  
                  disable-pagination
                >
                  <template v-slot:top>
                    <v-toolbar
                      elevation="2"
                      style="background: #f5f5f5; font-size: 26px"
                    >
                      <p class="mb-0">Confirm Product</p>
                      <v-spacer></v-spacer>

                      <v-divider class="mx-4" inset vertical></v-divider>
                    </v-toolbar>
                  </template>
                </v-data-table>
                
                <v-card-actions class="py-5">
                  <v-spacer></v-spacer>

                  <v-btn elevation="0" color="red" @click="CancelInvoice()">
                    Cancel
                  </v-btn>
                  <v-btn
                    @click="SaveApproveInvoice(item)"
                    elevation="0"
                    color="success"
                  >
                    Confirm Chalan
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
export default {
  data: () => ({
    crap: false,
    previews: {},
    SpecificationArray: [],
    searchspec: "",
    search: "",
    perPage1: '10',
    currentPage1: 1,
    totalItems1: 0,
    totalPages1: 0,
    pageSizes1: ['10', '30', '50', '100', 'All'],
    snackbar: false,
    text: "",
    color: "",
    check: true,
    editProductForm: false,
    loading: true,

    dialog1: false,
    new_category: "",

    dialog2: false,
    new_sub_category: "",

    dialog3: false,
    new_sub_sub_category: "",

    shares: ["Share with mother site", "Don't share with mother site"],
    select_val: ["kg", "pcs", "gm", "none"],
    TransitModelHeader: [
      { text: "ID", value: "id", sortable: false },
      { text: "product ID", value: "product_id", sortable: false },
      { text: "Specification ID", value: "specification_id", sortable: false },
      { text: "Title", value: "product_name" },
      { text: "Brand", value: "brand_name", sortable: false },
      { text: "Color", value: "product_color", sortable: false },
      { text: "Size", value: "product_size", sortable: false },
      { text: "quantity", value: "requested_qty", sortable: false },
      { text: "Purchase ", value: "purchase_price", sortable: false },
      { text: "Sell", value: "selling_price", sortable: false },
    ],
    products: [],
    contact: {},
    editedIndex: -1,
    allBrands: [],

    editedItem: {},
    user: {},

    title: "",
    brand: "",
    publish_st: "Published",
    description: "",
    key_features: [],
    quantity: "",
    // properties: true,
    point: "",
    price: "",
    purchase_price: "",
    discount_type: "",
    amount: "",
    discount_end_date: "",
    point_end_date: "",
    order: "",
    shipping: "Bangladesh",
    origin: "Bangladesh",
    manufactureCountries: [
      "Bangladesh",
      "India",
      "Pakistan",
      "China",
      "Sri Lanka",
      "Malaysia",
      "Indonesia",
      "Nepal",
      "Bhutan",
      "Maldives",
      "Japan",
      "Philippines",
      "Vietnam",
      "Turkey",
      "Iran",
      "Thailand",
      "Myanmar",
      "South Korea",
      "Saudi Arabia",
      "United Arab Emirates",
      "Singapore",
      "Kuwait",
      "Qatar",
      "Brunei",
      "Bahrain",
    ],
    warranty: "",

    searchchalan: "",
    AllWarehouseList: [],
    trnasferob: {},
    selected: [],
    Transfersavedialog: false,
    singleSelect: false,
    allchalanArray: [],
    viewchalandialog: false,
    tempobj: {
      data: {},
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  methods: {
    handlePageSizeChange(event) {
      // console.log('page size change method called');
      // console.log('event = ', event);
      this.perPage1 = (event);
      this.loading = true;
      this.TransferSelectInfo();
    },

    handlePageChange(value) {
      // console.log('handle page change value = ', value);
      this.currentPage1 = value;
      this.loading = true;
      this.TransferSelectInfo();
      window.scrollTo(0, 0);
    },

    initialize() {
      axios.get("manager/transferable_list").then((response) => {
        if (response.data.success) {
          this.AllWarehouseList = response.data.data;
          this.AllWarehouseList.forEach((element, index) => {
            element.flagvalue = element.value;
            element.value = index;
          });

          if (this.AllWarehouseList.length) {
            this.trnasferob = this.AllWarehouseList[0]
            this.TransferSelectInfo()
          }
        }
      });
    },

    savetransit(selected) {
      this.Transfersavedialog = true;
    },

    CancelInvoice() {
      this.Transfersavedialog = false;
    },

    TransferSelectInfo() {
      let perPage ;
      if (this.perPage1 === 'All'){
          perPage = this.totalItems1;
      }
      else
      {
          perPage = this.perPage1;
      }
       this.loading = true;
      axios
        .get(
          `productdetails/get_groupproduct_list/${this.trnasferob.flagvalue}/${this.trnasferob.id}/?page=${this.currentPage1}&size=${perPage}`
        )
        .then((response) => {
          if(response.status === 200){
            // console.log('results = ', response.data.results);
            this.allchalanArray = response.data.results;
            // console.log('order s= ', this.allchalanArray);
            this.totalItems1 = response.data.count;
            this.totalPages1 = parseInt(this.totalItems1 / this.perPage1);
            this.loading = false;
          }
          // this.loading = false;
          // if (response.data.success) {
          //   this.allchalanArray = response.data.data;
          // }
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    SaveApproveInvoice(trnasferob) {
      let datas = {
        user_id: this.user.user_id,
        transaction_info: this.selected,
      };
      
      axios
        .post(
          `manager/create_chalan_group/${this.trnasferob.flagvalue}/`,
          datas
        )
        .then((response) => {
          if (response.data.success) {
            this.tempobj = response.data;
            this.Transfersavedialog = false;
            this.allchalanArray = this.allchalanArray.filter((element) => {
              this.selected.some((item) => item.id == element.id);
            });
            this.selected = [];
            this.TransferSelectInfo();
            this.viewchalandialog = true;
          } else {
            this.text = response.data.message;
            this.color = "error";
            this.snackbar = true;
          }
        });
    },

    viewChalan(tempobj) {
      // console.log("this is clicked", tempobj);
      this.$router.push({
        path: "/dashboard/pages/admin_chalan/",
        query: { id: this.tempobj.data.id },
      });
    },
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  mounted() {
    this.initialize();
    this.Specificationselect();
    this.TransferSelectInfo();
  },
};
</script>


<style>
.cut {
  width: 500px;
  height: 560px;
  margin: 30px auto;
  margin-top: 0px;
}

.c-item {
  max-width: 800px;
  margin: 10px auto;
  margin-top: 20px;
}

.content {
  margin: auto;
  max-width: 1200px;
  margin-bottom: 100px;
}

.cropper {
  height: 600px;
  background: #ddd;
}

.cropper-view-box {
  outline: 1px solid red !important;
}

.thumbnailImage:hover {
  cursor: pointer;
}

.v-sheet--offset {
  top: -10px;
  position: relative;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.19rem;
  height: 48px;
}
</style>

